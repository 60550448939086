/* INTER FONT */

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  /* prettier-ignore */
  src: url("Inter-Thin.woff") format("woff"), url("Inter-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  /* prettier-ignore */
  src: url("Inter-Light.woff") format("woff"), url("Inter-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  /* prettier-ignore */
  src: url("Inter-Regular.woff") format("woff"), url("Inter-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  /* prettier-ignore */
  src: url("Inter-Medium.woff") format("woff"), url("Inter-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  /* prettier-ignore */
  src: url("Inter-SemiBold.woff") format("woff"), url("Inter-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  /* prettier-ignore */
  src: url("Inter-Bold.woff") format("woff"), url("Inter-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  /* prettier-ignore */
  src: url("Inter-ExtraBold.woff") format("woff"), url("Inter-ExtraBold.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  /* prettier-ignore */
  src: url("Inter-Black.woff") format("woff"), url("Inter-Black.woff2") format("woff2");
}

/* PX GROTESK FONT */
@font-face {
  font-family: "Px Grotesk";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  /* prettier-ignore */
  src: url("Px-Grotesk-Bold.woff") format("woff"), url("Px-Grotesk-Bold.woff2") format("woff2");
}
